import { createSlice } from "@reduxjs/toolkit";
import { InitPagination } from "../../../../utils/pagination";
import { ILoanRequestsState, InitLoanRequestsSearchParams } from "./types";

const initialState: ILoanRequestsState = {
    loanRequests: [],
    loanRequestsPagination: InitPagination,
    loanRequestsLoading: false,
    loanRequestsError: "",
    loanRequestsSearchParams: InitLoanRequestsSearchParams
}




const loanRequestsSlice = createSlice({
    name: "loanRequests",
    initialState,
    reducers: {
        setLoanRequestsLoading: (state, action) => {
            state.loanRequestsLoading = action.payload
        },
        setLoanRequestsError: (state, action) => {
            state.loanRequestsError = action.payload
        },
        setLoanRequestsSearchParams: (state, action) => {
            state.loanRequestsSearchParams = action.payload
        },
        saveLoanRequestsPagination: (state, action) => {
            state.loanRequestsPagination = action.payload
        },
        saveLoanRequests: (state, action) => {
            state.loanRequests = action.payload
        },
        reSetLoanRequests: (state) => {
            state.loanRequests = []
            state.loanRequestsPagination = InitPagination
            state.loanRequestsSearchParams = InitLoanRequestsSearchParams
        }
    }
})

export const {
    setLoanRequestsError,
    setLoanRequestsLoading,
    saveLoanRequests,
    saveLoanRequestsPagination,
    setLoanRequestsSearchParams,
    reSetLoanRequests,
} = loanRequestsSlice.actions

const loanRequestsReducer = loanRequestsSlice.reducer

export default loanRequestsReducer
