import { createSlice } from "@reduxjs/toolkit";
import { InitPagination } from "../../../../utils/pagination";

import { ILoanContractsState } from "./types";

const initialState: ILoanContractsState = {
    loanContract: null,
    loanContractLoading: false,
    loanContractError: "",
    fcoCodes: null,
    fcoData: null,
    fcoLoading: false,
    fcoError: "",
    loanContractRepaymentHistories: [],
    loanContractsRepaymentHistoryPagination: InitPagination,
    loanContractsRepaymentHistoryLoading: false,
    loanContractsRepaymentHistoryError: "",
    loanContractInterestAccruedData: [],
    loanContractInterestAccruedPagination: InitPagination,
    loanContractInterestAccruedLoading: false,
    loanContractInterestAccruedError: "",
    loanContractRepaymentScheduleData: [],
    loanContractRepaymentSchedulePagination: InitPagination,
    loanContractRepaymentScheduleLoading: false,
    loanContractRepaymentScheduleError: "",
    loanContractInterestBreakDown: null,
    loanContractInterestBreakDownLoading: false,
    loanContractInterestBreakDownError: "",
    loanContractParticipantIncomeDetails: null,
    loanContractParticipantIncomeDetailsLoanding: false,
    loanContractParticipantIncomeDetailsError: "",
}


const loanContractDetailSlice = createSlice({
    name: "loanContractDetails",
    initialState,
    reducers: {
        setLoanContractLoading: (state, action) => {
            state.loanContractLoading = action.payload
        },
        setLoanContractError: (state, action) => {
            state.loanContractError = action.payload
        },
        saveLoanContract: (state, action) => {

            state.loanContract = action?.payload?.responseBody
        },
        resetContract: (state) => {
            state.loanContractLoading = false;
            state.loanContract = null;
            state.loanContractError = "";
        },

        saveFcoCode: (state, action) => {
            state.fcoCodes = action?.payload
        },
        resetFCOCodes: (state) => {
            state.fcoCodes = null;
        },

        setFcoLoading: (state, action) => {
            state.fcoLoading = action.payload
        },
        setFcoError: (state, action) => {
            state.fcoError = action.payload
        },
        saveFcoDatas: (state, action) => {
            state.fcoData = action?.payload?.responseBody
        },
        resetFcosData: (state) => {
            state.fcoLoading = false;
            state.fcoData = null;
            state.fcoError = "";
        },
        resetFcoError: (state) => {
            state.fcoError = "";
        },

        setLoanContractsRepaymentHistoryLoading: (state, action) => {
            state.loanContractsRepaymentHistoryLoading = action.payload
        },
        setLoanContractsRepaymentHistoryError: (state, action) => {
            state.loanContractsRepaymentHistoryError = action.payload
        },
        saveLoanContractsRepaymentHistoryPagination: (state, action) => {
            state.loanContractsRepaymentHistoryPagination = action.payload
        },
        saveLoanContractsRepaymentHistory: (state, action) => {
            state.loanContractRepaymentHistories = action.payload
        },
        resetLoanContractsRepaymentHistory: (state) => {
            state.loanContractRepaymentHistories = [];
            state.loanContractsRepaymentHistoryPagination = InitPagination
        },

        setLoanContractInterestAccruedLoading: (state, action) => {
            state.loanContractInterestAccruedLoading = action.payload
        },
        setLoanContractInterestAccruedError: (state, action) => {
            state.loanContractInterestAccruedError = action.payload
        },
        saveLoanContractInterestAccruedPagination: (state, action) => {
            state.loanContractInterestAccruedPagination = action.payload
        },
        saveLoanContractInterestAccrued: (state, action) => {
            state.loanContractInterestAccruedData = action.payload
        },
        resetLoanContractInterestAccrued: (state) => {
            state.loanContractInterestAccruedData = [];
            state.loanContractInterestAccruedPagination = InitPagination;
        },

        setLoanContractRepaymentScheduleLoading: (state, action) => {
            state.loanContractRepaymentScheduleLoading = action.payload
        },
        setLoanContractRepaymentScheduleError: (state, action) => {
            state.loanContractRepaymentScheduleError = action.payload
        },
        saveLoanContractRepaymentSchedulePagination: (state, action) => {
            state.loanContractRepaymentSchedulePagination = action.payload
        },
        saveLoanContractRepaymentSchedule: (state, action) => {
            state.loanContractRepaymentScheduleData = action.payload
        },
        resetLoanContractRepaymentSchedule: (state) => {
            state.loanContractRepaymentScheduleData = []
            state.loanContractRepaymentSchedulePagination = InitPagination
        },

        saveLoanContractInterestBreakDown: (state, action) => {
            state.loanContractInterestBreakDown = action.payload
        },
        setLoanContractInterestBreakDownLoading: (state, action) => {
            state.loanContractInterestBreakDownLoading = action.payload
        },
        setLoanContractInterestBreakDownError: (state, action) => {
            state.loanContractInterestBreakDownError = action.payload
        },
        resetLoanContractInterestBreakDown: (state) => {
            state.loanContractInterestBreakDown = null;
        },

        saveLoanContractParticipantIncomeDetails: (state, action) => {
            state.loanContractParticipantIncomeDetails = action.payload
        },
        saveLoanContractParticipantIncomeDetailsError: (state, action) => {
            state.loanContractParticipantIncomeDetailsError = action.payload
        },
        setLoanContractParticipantIncomeDetailsLoading: (state, action) => {
            state.loanContractParticipantIncomeDetailsLoanding = action.payload
        }
    }
})


export const {
    setLoanContractLoading,
    setLoanContractError,
    resetContract,
    saveLoanContract,
    saveFcoCode,
    resetFCOCodes,
    setFcoLoading,
    setFcoError,
    saveFcoDatas,
    resetFcosData,
    resetFcoError,
    setLoanContractsRepaymentHistoryLoading,
    setLoanContractsRepaymentHistoryError,
    saveLoanContractsRepaymentHistoryPagination,
    saveLoanContractsRepaymentHistory,
    resetLoanContractsRepaymentHistory,
    setLoanContractInterestAccruedLoading,
    setLoanContractInterestAccruedError,
    saveLoanContractInterestAccruedPagination,
    saveLoanContractInterestAccrued,
    resetLoanContractInterestAccrued,
    setLoanContractRepaymentScheduleLoading,
    setLoanContractRepaymentScheduleError,
    saveLoanContractRepaymentSchedulePagination,
    saveLoanContractRepaymentSchedule,
    resetLoanContractRepaymentSchedule,
    saveLoanContractInterestBreakDown,
    setLoanContractInterestBreakDownLoading,
    setLoanContractInterestBreakDownError,
    resetLoanContractInterestBreakDown,
    saveLoanContractParticipantIncomeDetails,
    saveLoanContractParticipantIncomeDetailsError,
    setLoanContractParticipantIncomeDetailsLoading,
} = loanContractDetailSlice.actions 


const loanContractDetailsReducer = loanContractDetailSlice.reducer

export default loanContractDetailsReducer 