import { createSlice } from '@reduxjs/toolkit';
import { IBUSINESSOWNERGUARANTORDETAILS  } from './businessOwnerGuarantorSlice.types';



export const initialState: IBUSINESSOWNERGUARANTORDETAILS = {
    businessOwnerGuarantorDetailsData:[],
   businessOwnerGuarantorDetailsloading:false,
   businessOwnerGuarantorDetailsSuccess:false,
   businessOwnerGuarantorDetailsError:""
};



const businessOwnerGuarantortDetailsSlice = createSlice({
    name: "businessOwnerGuarantorDetailsId",
    initialState,
    reducers: {
        saveBusinessOwnerGuarantorDetails: (state, action) => {
            state.businessOwnerGuarantorDetailsData = action?.payload?.responseBody
           
        },
        setBusinessOwnerGuarantorLoading: (state, action) => {
            state.businessOwnerGuarantorDetailsloading = action.payload
        },
        saveBusinessOwnerGuarantorDetailsError: (state, action) => {
            state.businessOwnerGuarantorDetailsError = action.payload
        },
        resetLoanRequestBusinessOwnerGuarantorState: (state) => {
            state.businessOwnerGuarantorDetailsError =""
            state.businessOwnerGuarantorDetailsloading   = false 
            state.businessOwnerGuarantorDetailsData  =[]
            state.businessOwnerGuarantorDetailsSuccess=false
        },
     
    }
})

export const {saveBusinessOwnerGuarantorDetails, setBusinessOwnerGuarantorLoading, saveBusinessOwnerGuarantorDetailsError,resetLoanRequestBusinessOwnerGuarantorState } = businessOwnerGuarantortDetailsSlice.actions
const businessOwnerGuarantortDetailsReducer = businessOwnerGuarantortDetailsSlice.reducer


export default businessOwnerGuarantortDetailsReducer

