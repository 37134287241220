import { createSlice } from "@reduxjs/toolkit";
import { CREDIT_REPORT_CATEGORY } from "../CreditReportInformationPage/types";
import { ICreditReportInformationState } from "./types";

const initialState: ICreditReportInformationState = {
    creditReportInformation: {
        [CREDIT_REPORT_CATEGORY.BUSINESS]: null,
        [CREDIT_REPORT_CATEGORY.BUSINESS_OWNER]: null,
    },
    creditReportInformationError: "",
    creditReportInformationLoading: false,
    creditReportLoanDetails: null
}


const creditReportInformationSlice = createSlice({
    name: "creditReportInformationState",
    initialState,
    reducers: {
        setCreditReportInformationLoading: (state, action) => {
            state.creditReportInformationLoading = action.payload
        },
        setCreditReportInformationError: (state, action) => {
            state.creditReportInformationError = action.payload
        },
        saveCreditReportInformation: (state, action: { type: string, payload: { data: any, key: CREDIT_REPORT_CATEGORY }}) => {
            state.creditReportInformation[action.payload.key] = action.payload.data
        },
        saveCreditReportLoanDetails: (state, action) => {
            state.creditReportLoanDetails = action.payload
        },
        resetCreditReportInformation: (state) => {
            state.creditReportInformation.BUSINESS = null
            state.creditReportInformation.BUSINESS_OWNER = null
        },
    }
})

export const {
    setCreditReportInformationError,
    setCreditReportInformationLoading,
    saveCreditReportInformation,
    saveCreditReportLoanDetails,
    resetCreditReportInformation,
} = creditReportInformationSlice.actions

const creditReportInformationReducer = creditReportInformationSlice.reducer

export default creditReportInformationReducer