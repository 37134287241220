import { combineReducers } from '@reduxjs/toolkit'
import layoutReducer from '../layouts/layoutSlice'
import authReducer from '../modules/Auth/authSlice'
import loanRequestDetailsReducer from '../modules/Dashboard/LoanRequestDetails/loanRequestDetailsSLice'
import businessOwnerloanRequestDetailsReducer from '../modules/Dashboard/LoanRequestBusinessDetails/BusinessOwnerDetails/businessOwnerDetailsSLice'
import businessAccTurnOverDetailsReducer from '../modules/Dashboard/LoanRequestBusinessDetails/BusinessOwnerAccount/businessOwnerAccountSLice'
import businessOwnerGuarantortDetailsReducer from '../modules/Dashboard/LoanRequestBusinessDetails/BusinessOwnerGuarantor/businessOwnerGuarantorSLice'
import rejectLoanRequestSliceReducer from '../modules/Dashboard/LoanRequestBusinessDetails/MakeDecision/makeDecisionSLice'
import creditReportInformationReducer from '../modules/Dashboard/CreditReportInformation/CreditReportInformationSlice'
import loanAgreementReducer from '../modules/Dashboard/LoanAgreement/LoanAgreementSlice'
import loanRequestBusinessDetailsReducer from '../modules/Dashboard/LoanRequestBusinessDetails/LoanRequestBusinessDetailsSlice'
import loanRequestsReducer from '../modules/Dashboard/LoanRequests/LoanRequestsSlice'
import approvalReducer from '../modules/Dashboard/LoanRequestApproval/LoanRequestApprovalSlice'
import loanContractsReducer from '../modules/Dashboard/LoanContracts/slice'
import loanContractDetailsReducer from '../modules/Dashboard/LoanContractDetails/loanContractSlics'
import locationReducer from '../modules/Dashboard/Utils/LocationUtils/Slice'
import loanComissionsReducer   from '../modules/Dashboard/Commissions/commissionSlice'
import incomePayoutHistoryReducer from '../modules/Dashboard/IncomePayoutHistory/Slice'
import WalletReducer from '../modules/Dashboard/Wallet/Slice'

export const rootReducer = combineReducers({
    auth: authReducer,
    layout: layoutReducer,
    loanRequestDetails:loanRequestDetailsReducer ,
    businessOwnerloanRequestDetails:businessOwnerloanRequestDetailsReducer,
    businessAccTurnOverDetails:businessAccTurnOverDetailsReducer,
    businessOwnerGuarantortDetails:businessOwnerGuarantortDetailsReducer,
    rejectLoanRequest:rejectLoanRequestSliceReducer,
    loanRequests: loanRequestsReducer,
    loanRequestBusinessDetails: loanRequestBusinessDetailsReducer,
    creditReportInformationState: creditReportInformationReducer,
    loanAgreementState: loanAgreementReducer,
    loanApprovalState: approvalReducer,
    loanContractsState: loanContractsReducer,
    loanContractDetailsState:loanContractDetailsReducer,
    locationState: locationReducer,
    loanCommissionsState:loanComissionsReducer,
    incomePayoutHistoryState: incomePayoutHistoryReducer,
    wallet: WalletReducer,
})



   

