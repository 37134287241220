export const InitPagination = {
    current: 0,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    numberOfElements: 0,
    first: true,
    last: true,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '30', '40'],
}

export type PAGINATION = typeof InitPagination

export const extractPaginationData = (responseBody: any) => {
    const { number, size, totalElements, totalPages, numberOfElements, first, last } = responseBody
  
    return {
        ...InitPagination,
        current: number,
        pageSize: size,
        total: totalElements,
        totalPages,
        numberOfElements,
        first,
        last,
      }
  }
  
