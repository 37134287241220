import { Config } from "./type";

const CONFIG_STAGING: Config = {
  VERSION: "0.0.1",
  BASE_URL: "https://user-management.staging.teamapt.com/api/v1",
  WCL_PORTFOLIO_FRONTOFFICE_URL: "https://wcl-portfolio-service.staging.teamapt.com/api/v1",
  LOCATION_BASE_URL: "https://location-v2.api.new.kuwego.staging.teamapt.com/api/v1",
  MAPS_API_KEY: "AIzaSyBRdXreiqf0s-ak95kUG_Ws_3ms7CfG5QE",
  excluded_features: []
};


export default CONFIG_STAGING;
