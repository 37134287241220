import CONFIG_PRODUCTION from "./config-production";
import CONFIG_STAGING from "./config-staging";
import { Config } from "./type";

type environments = "playground" | "test" | "dev" | "staging" | "production";

const HOLDER: Config = {
  VERSION: "0.0.1",
  BASE_URL: "https://user-management.development.moniepoint.com/api/v1",
  WCL_PORTFOLIO_FRONTOFFICE_URL: "https://wcl-portfolio-service.development.moniepoint.com/api/v1",
  LOCATION_BASE_URL: "https://api.teamapt-location-service.new.kuwego.development.teamapt.com/api/v1",
  MAPS_API_KEY: "AIzaSyBRdXreiqf0s-ak95kUG_Ws_3ms7CfG5QE",
  excluded_features: []
};

const configMap = {
  playground: HOLDER,
  test: HOLDER,
  dev: HOLDER,
  staging: CONFIG_STAGING,
  production: CONFIG_PRODUCTION,
};

const config =
  configMap[
    (process.env.REACT_APP_ENV as environments)
      ? (process.env.REACT_APP_ENV as environments)
      : "dev"
  ];

export default config;
