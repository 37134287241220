import { PAGINATION } from "../../../../utils/pagination"
import { LoanRequestStatus } from "../../Utils/types"

export interface ILoanRequestsState {
    loanRequests: ILoanRequest[]
    loanRequestsPagination: PAGINATION
    loanRequestsLoading: boolean
    loanRequestsError: string
    loanRequestsSearchParams: LoanRequestsSearchParams
}

export interface ILoanRequest {
    id: number
    createdOn: string
    "lastModifiedOn": string
    "createdBy": string
    "loanRequestReference": string
    "businessOwnerFirstName": string
    "businessOwnerLastName": string
    "businessName": string
    "amount": number,
    "status": LoanRequestStatus
    "tenure": number,
    "tenureUnits": string
    "requestDate": string
}

export type LoanRequestsSearchParams = {
    requestStartDate?: string
    requestEndDate?: string
    status?: string
    isRmApprovalBlocked?: boolean
}

export const InitLoanRequestsSearchParams: LoanRequestsSearchParams = {
    requestStartDate: "",
    requestEndDate: "",
    status: "",
}