import { createSlice } from "@reduxjs/toolkit"
import { ILayoutState } from "./layoutSlice.types"

const initialState: ILayoutState = {
    showSidebar: true,
    renderLayout: true
}



const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setSidebar: (state, action) => {
            state.showSidebar = action.payload
        },
        setRenderLayout: (state, action) => {
            state.renderLayout = action.payload
        }
    }
})

export const { setSidebar, setRenderLayout } = layoutSlice.actions

 const layoutReducer = layoutSlice.reducer

 export default layoutReducer
