import { createSlice } from "@reduxjs/toolkit";
import { ILocationState } from "./types";

const initialState: ILocationState = {
    states: [],
    statesLoading: false,
    statesError: "",
    LGAs: [],
    LGAsLoading: false,
    LGAsError: "",
    LGAsByState: [],
    LGAsByStateLoading: false,
    LGAsByStateError: "",
}

const locationSlice = createSlice({
    name: "utils",
    initialState,
    reducers: {
        loadStates: (state, action) => {
            state.states = action.payload
        },
        setStatesLoading: (state, action) => {
            state.statesLoading = action.payload
        },
        setStatesError: (state, action) => {
            state.statesError = action.payload
        },

        loadLGAs: (state, action) => {
            state.LGAs = action.payload
        },
        setLGAsLoading: (state, action) => {
            state.LGAsLoading = action.payload
        },
        setLGAsError: (state, action) => {
            state.LGAsError = action.payload
        },

        loadLGAsByState: (state, action) => {
            state.LGAsByState = action.payload
        },
        setLGAsByStateLoading: (state, action) => {
            state.LGAsByStateLoading = action.payload
        },
        setLGAsByStateError: (state, action) => {
            state.LGAsByStateError = action.payload
        },
    }
})


export const {
    loadStates,
    setStatesLoading,
    setStatesError,
    loadLGAs,
    setLGAsLoading,
    setLGAsError,
    loadLGAsByState,
    setLGAsByStateLoading,
    setLGAsByStateError,
} = locationSlice.actions

const locationReducer = locationSlice.reducer


export default locationReducer
