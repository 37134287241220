import { createSlice } from '@reduxjs/toolkit';
import {  IMAKELOANREQUESTDECISION  } from './makeDecisionSlice.types';



export const initialState:  IMAKELOANREQUESTDECISION= {
    success:false,
    loading: false, 
    error:"",
};


const rejectLoanRequestSlice = createSlice({
    name: "makeDecision",
    initialState,
    reducers: {
        setRejectSuccess: (state, action) => {
            state.success = action.payload
           
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        resetMakeDecisionState: (state) => {
            state.success =false
            state.loading = false 
            state.error =""
        },  
        ErrorReset:(state)=>{
            state.error =""
        }
     
    }
})

export const {setRejectSuccess,ErrorReset,setLoading,setError,resetMakeDecisionState } = rejectLoanRequestSlice.actions
const rejectLoanRequestSliceReducer = rejectLoanRequestSlice.reducer


export default rejectLoanRequestSliceReducer

