import { createSlice } from "@reduxjs/toolkit";
import { InitPagination } from "../../../../utils/pagination";
import { WalletSate } from "./types";



const initialState: WalletSate = {
    cashoutAccountsDetails: [],
    cashoutAccountsDetailsError: "",
    cashoutAccountsDetailsLoading: false,
    payoutAccountBalance: null,
    payoutAccountBalanceError: "",
    payoutAccountBalanceLoading: false,
    payoutTransactionHistory: [],
    payoutTransactionHistoryError: "",
    payoutTransactionHistoryLoading: false,
    payoutTransactionHistoryPagination: InitPagination,
    payoutTransactionHistorySearchParams: {},
    cashoutError: "",
    cashoutLoading: false
}


const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        saveCashoutAccountDetails: (state, action) => {
            state.cashoutAccountsDetails = action.payload
        },
        setCashoutAccountDetailsLoading: (state, action) => {
            state.cashoutAccountsDetailsLoading = action.payload
        },
        setCashoutAccountDetailsError: (state, action) => {
            state.cashoutAccountsDetailsError = action.payload
        },
        resetCashoutAccountDetails: (state) => {
            state.cashoutAccountsDetails = []
        },

        savePayoutAccountBalance: (state, action) => {
            state.payoutAccountBalance = action.payload
        },
        setPayoutAccountBalanceError: (state, action) => {
            state.payoutAccountBalanceError = action.payload
        },
        setPayoutAccountBalanceLoading: (state, action) => {
            state.payoutAccountBalanceLoading = action.payload
        },
        resetPayoutAccountBalance: (state) => {
            state.payoutAccountBalance = null
        },

        savePayoutTransactionHistory: (state, action) => {
            state.payoutTransactionHistory = action.payload
        },
        setPayoutTransactionHistoryPagination: (state, action) => {
            state.payoutTransactionHistoryPagination = action.payload
        },
        setPayoutTransactionHistorySearchParams: (state, action) => {
            state.payoutTransactionHistorySearchParams = action.payload
        },
        setPayoutTransactionHistoryError: (state, action) => {
            state.payoutTransactionHistoryError = action.payload
        },
        setPayoutTransactionHistoryLoading: (state, action) => {
            state.payoutTransactionHistoryLoading = action.payload
        },
        resetPayoutTransactionHistory: (state) => {
            state.payoutTransactionHistory = []
            state.payoutTransactionHistorySearchParams = {}
            state.payoutTransactionHistoryPagination = InitPagination
        },

        setCashoutLoading: (state, action) => {
            state.cashoutLoading = action.payload
        },
        setCashoutError: (state, action) => {
            state.cashoutError = action.payload
        },
    }
})


export const {
    saveCashoutAccountDetails,
    setCashoutAccountDetailsError,
    setCashoutAccountDetailsLoading,
    resetCashoutAccountDetails,

    savePayoutAccountBalance,
    setPayoutAccountBalanceError,
    setPayoutAccountBalanceLoading,
    resetPayoutAccountBalance,

    savePayoutTransactionHistory,
    setPayoutTransactionHistoryError,
    setPayoutTransactionHistoryLoading,
    setPayoutTransactionHistoryPagination,
    setPayoutTransactionHistorySearchParams,
    resetPayoutTransactionHistory,

    setCashoutError,
    setCashoutLoading,
} = walletSlice.actions


const WalletReducer = walletSlice.reducer
export default WalletReducer

