import { createSlice } from '@reduxjs/toolkit';
import { IBUSINESSOWNERDETAILS  } from './businessOwnerDetailsSlice.types';



export const initialState: IBUSINESSOWNERDETAILS = {
    businessOwnerDetailsData:[],
   businessOwnerDetailsloading:false,
   businessOwnerDetailsSuccess:false,
   businessOwnerDetailsError:""
};


const businessOwnerLoanRequestDetailsSlice = createSlice({
    name: "businessOwnerLoanRequestDetailsId",
    initialState,
    reducers: {
        saveBusinessOwnerLoanRequestDetails: (state, action) => {
            state.businessOwnerDetailsData = action?.payload?.responseBody
           
        },
        setBusinessOwnerLoading: (state, action) => {
            state.businessOwnerDetailsloading = action.payload
        },
        saveBusinessOwnerLoanRequestDetailsError: (state, action) => {
            state.businessOwnerDetailsError = action.payload
        },
        resetLoanRequestBusinessOwnerDetailsState: (state) => {
            state.businessOwnerDetailsError =""
            state.businessOwnerDetailsloading  = false 
            state.businessOwnerDetailsData  =[]
            state.businessOwnerDetailsSuccess=false
        },
     
    }
})

export const {saveBusinessOwnerLoanRequestDetails,setBusinessOwnerLoading,saveBusinessOwnerLoanRequestDetailsError,resetLoanRequestBusinessOwnerDetailsState } = businessOwnerLoanRequestDetailsSlice.actions
const businessOwnerloanRequestDetailsReducer = businessOwnerLoanRequestDetailsSlice.reducer


export default businessOwnerloanRequestDetailsReducer 

