import React from "react"
import './styles.css'


interface Props {
    Logo?: React.ReactNode;
}


const GlobalPageLoader: React.FC<Props> = ({ Logo }) => {
    return (
        <>
            <section className='global-page-loader-container'>
                {
                    Logo ||
                    <span className="default-logo">
                        {<DefaultLogo />}
                    </span>
                }

                <div className='loader-trail'>
                    <div className='loader-stroke'></div>
                </div>
            </section>
        </>
    )
}

const DefaultLogo = () => (
    <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.5152 96.0969H48.1686C25.7593 96.0395 10.7426 91.3896 7.62381 88.5194C4.33171 85.5917 0 70.4366 0 48.0485C0 25.7177 4.33171 10.5052 7.62381 7.57753C10.7426 4.70726 25.817 0.0574056 48.1686 0H48.5152C70.9246 0.0574056 85.9412 4.70726 89.06 7.57753C92.3521 10.5052 96.6838 25.7177 96.6838 48.0485C96.6838 70.3792 92.3521 85.5917 89.06 88.5194C85.9412 91.4471 70.9246 96.0969 48.5152 96.0969Z" fill="url(#paint0_linear)" />
        <path d="M70.6359 50.9171L68.7877 28.4715H57.6407C57.6407 28.4715 49.1506 45.7506 48.3997 48.1042H48.2842L39.332 28.4715H28.1273L26.2791 47.932L24.7197 67.6795H31.5349C34.4805 67.6795 35.6356 66.4166 35.8667 63.3167C35.8667 63.3167 37.0218 45.2339 36.8485 42.5933H36.964L37.3106 43.7988C37.7726 45.4061 44.1258 60.8482 44.1258 60.8482H52.5004C52.5004 60.8482 58.8536 45.6358 59.6622 42.5933H59.7777L60.7596 63.3167C60.9906 66.4166 62.2035 67.6795 65.0913 67.6795H71.9065L70.6359 50.9171Z" fill="white" />
        <defs>
            <linearGradient id="paint0_linear" x1="7.63415" y1="7.59274" x2="88.5707" y2="89.0236" gradientUnits="userSpaceOnUse">
                <stop stopColor="#05C2FF" />
                <stop offset="1" stopColor="#0357EE" />
            </linearGradient>
        </defs>
    </svg>
);

export default GlobalPageLoader;
