import { createSlice } from "@reduxjs/toolkit";
import { ILoanAgreementState } from "./types";

const initialState: ILoanAgreementState = {
    loanAgreementData: null,
    loanAgreementError: "",
    loanAgreementLoading: false,
}



const loanAgreementSlice = createSlice({
    name: "loanAgreementState",
    initialState,
    reducers: {
        setLoanAgreementLoading: (state, action) => {
            state.loanAgreementLoading = action.payload
        },
        setLoanAgreementError: (state, action) => {
            state.loanAgreementError = action.payload
        },
        saveLoanAgreementData: (state, action) => {
            state.loanAgreementData = action.payload
        }
    }
})


export const {
    setLoanAgreementError,
    setLoanAgreementLoading,
    saveLoanAgreementData,
} = loanAgreementSlice.actions

const loanAgreementReducer = loanAgreementSlice.reducer
export default loanAgreementReducer
