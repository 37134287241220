import { createSlice } from '@reduxjs/toolkit';
import { IBUSINESSOWNERACCOUNT  } from './businessOwnerAccountSlice.types';



export const initialState: IBUSINESSOWNERACCOUNT = {
    businessAccData: null,
    businessAccloading:false,
    businessAccSuccess:false,
    businessAccsError:""
};



const businessOwnerAccountDetailsSlice = createSlice({
    name: "businessOwnerLoanRequestDetailsId",
    initialState,
    reducers: {
        saveAccountDetails: (state, action) => {
            state.businessAccData= action?.payload?.responseBody
        },
        setBusinessAccountLoading: (state, action) => {
            state.businessAccloading= action.payload
        },
        saveBusinessAccountDetailsError: (state, action) => {
            state.businessAccsError = action.payload
        },
        resetLoanRequestBusinessAccountDetailsState: (state) => {
            state.businessAccsError =""
            state.businessAccloading  = false 
            state.businessAccData  = null
            state.businessAccSuccess=false
        },
     
    }
})

export const {saveAccountDetails, setBusinessAccountLoading, saveBusinessAccountDetailsError,resetLoanRequestBusinessAccountDetailsState } = businessOwnerAccountDetailsSlice.actions
const businessAccTurnOverDetailsReducer = businessOwnerAccountDetailsSlice.reducer


export default businessAccTurnOverDetailsReducer

