import { IBusinessCreditReportInformation, IBusinessOwnerCreditReportInformation, ICreditReportLoanDetails } from "../CreditReportInformationSlice/types"

export enum CREDIT_REPORT_CATEGORY {
    BUSINESS_OWNER = "BUSINESS_OWNER",
    BUSINESS = "BUSINESS",
}

export enum CREDIT_REPORT_BREAK_DOWN_CATEGORY {
    ACTIVE = "ACTIVE",
    COMPLETED = "COMPLETED",
    INCONCLUSIVE = "INCONCLUSIVE",
}

export enum CREDIT_REPORT_BREAK_DOWN_STATUS {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
}

export interface CreditReportInformationPageProps {
    reviewed: boolean
    hasApproved: boolean
    canApprove: boolean
    onReviewCompleted(): void
    onReviewedChange(reviewed: boolean): void
    creditReportCategory: CREDIT_REPORT_CATEGORY
    creditReportBreakDownCategory: CREDIT_REPORT_BREAK_DOWN_CATEGORY
    onCreditReportCategoryChange(category: CREDIT_REPORT_CATEGORY): void
    onCreditReportBreakDownCategoryChange(category: CREDIT_REPORT_BREAK_DOWN_CATEGORY): void
    onViewCreditReportBreakDownDetails(data: ICreditReportLoanDetails, category: CREDIT_REPORT_BREAK_DOWN_CATEGORY): void
    creditReportInformationLoading: boolean
    creditReportInformationError: string
    creditReportInformation: {
        [CREDIT_REPORT_CATEGORY.BUSINESS]: null | IBusinessCreditReportInformation
        [CREDIT_REPORT_CATEGORY.BUSINESS_OWNER]: null | IBusinessOwnerCreditReportInformation
    }
    fetchCreditReportInformation: (
        reference: string, 
        category: CREDIT_REPORT_CATEGORY
    ) => Promise<void>
    loanDetailsData:any
}
