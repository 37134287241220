import { createSlice } from "@reduxjs/toolkit";
import { InitPagination } from "../../../../utils/pagination";
import { IncomePayoutHistoryState } from "./types";

const initialState: IncomePayoutHistoryState = {
    incomePayoutHistory: [],
    incomePayoutHistoryPagination: InitPagination,
    incomePayoutHistorySearchParams: {},
    incomePayoutHistoryLoading: false,
    incomePayoutHistoryError: "",
    incomePayoutHistoryDetails: null,
    incomePayoutHistoryDetailsLoading: false,
    incomePayoutHistoryDetailsError: "",
}


const incomePayoutHistorySlice = createSlice({
    name: "incomePayoutHistory",
    initialState,
    reducers: {
        saveIncomePayoutHistory: (state, action) => {
            state.incomePayoutHistory = action.payload
        },
        setIncomePayoutHistoryPagination: (state, action) => {
            state.incomePayoutHistoryPagination = action.payload
        },
        setIncomePayoutHistorySearchParams: (state, action) => {
            state.incomePayoutHistorySearchParams = action.payload
        },
        setIncomePayoutHistoryLoading: (state, action) => {
            state.incomePayoutHistoryLoading = action.payload
        },
        setIncomePayoutHistoryError: (state, action) => {
            state.incomePayoutHistoryError = action.payload
        },
        resetIncomePayoutHistory: (state) => {
            state.incomePayoutHistory = []
            state.incomePayoutHistoryPagination = InitPagination
            state.incomePayoutHistorySearchParams = {}
        },


        saveIncomePayoutHistoryDetails: (state, action) => {
            state.incomePayoutHistoryDetails = action.payload
        },
        setIncomePayoutHistoryDetailsLoading: (state, action) => {
            state.incomePayoutHistoryDetailsLoading = action.payload
        },
        setIncomePayoutHistoryDetailsError: (state, action) => {
            state.incomePayoutHistoryDetailsError = action.payload
        },
        resetIncomePayoutHistoryDetails: (state) => {
            state.incomePayoutHistoryDetails = null
        }
    }
})

export const {
    saveIncomePayoutHistory,
    setIncomePayoutHistoryPagination,
    setIncomePayoutHistorySearchParams,
    setIncomePayoutHistoryLoading,
    setIncomePayoutHistoryError,
    resetIncomePayoutHistory,
    saveIncomePayoutHistoryDetails,
    setIncomePayoutHistoryDetailsError,
    setIncomePayoutHistoryDetailsLoading,
    resetIncomePayoutHistoryDetails,
} = incomePayoutHistorySlice.actions


const incomePayoutHistoryReducer = incomePayoutHistorySlice.reducer

export default incomePayoutHistoryReducer
