import { createSlice } from "@reduxjs/toolkit";
import { InitPagination } from "../../../../utils/pagination";
import { ILoanContractsState } from "./types";


export const defaultRiskyTurnoverContractsCount = 0;

const initialState: ILoanContractsState = {
    loanContracts: [],
    loanContractsPagination: InitPagination,
    loanContractsSearchParams: {},
    loanContractsLoading: false,
    loanContractsError: "",

    riskyTurnoverContractsCount: 0,
    riskyTurnoverContractsCountError: "",
    riskyTurnoverContractsCountLoading: false
}



const loanContractsSlice = createSlice({
    name: "loanContracts",
    initialState,
    reducers: {
        setLoanContractsLoading: (state, action) => {
            state.loanContractsLoading = action.payload
        },
        setLoanContractsError: (state, action) => {
            state.loanContractsError = action.payload
        },
        saveLoanContractsSearchParams: (state, action) => {
            state.loanContractsSearchParams = action.payload
        },
        saveLoanContractsPagination: (state, action) => {
            state.loanContractsPagination = action.payload
        },
        saveLoanContracts: (state, action) => {
            state.loanContracts = action.payload
        },
        resetLoanContracts: (state) => {
            state.loanContracts = []
            state.loanContractsPagination = InitPagination
            state.loanContractsSearchParams = {}
        },

        saveRiskyTurnoverContractsCount: (state, action) => {
            state.riskyTurnoverContractsCount = action.payload
        },
        setRiskyTurnoverContractsCountLoading: (state, action) => {
            state.riskyTurnoverContractsCountLoading = action.payload
        },
        setRiskyTurnoverContractsCountError: (state, action) => {
            state.riskyTurnoverContractsCountError = action.payload
        }
    }
})


export const {
    setLoanContractsLoading,
    setLoanContractsError,
    saveLoanContractsPagination,
    saveLoanContractsSearchParams,
    saveLoanContracts,
    resetLoanContracts,

    saveRiskyTurnoverContractsCount,
    setRiskyTurnoverContractsCountError,
    setRiskyTurnoverContractsCountLoading,
} = loanContractsSlice.actions

const loanContractsReducer = loanContractsSlice.reducer

export default loanContractsReducer