import { createSlice } from "@reduxjs/toolkit";
import { InitPagination } from "../../../../utils/pagination";

import { ICommissionIncomeState } from "./types";

const initialState: ICommissionIncomeState = {
    allTransactionLoading:false,
    allTransactionError:"",
    allTransactionData:[],
    allTransactionPagination:InitPagination,
    
    loanCommissionDetailLoading: false,
    loanCommissionDetailError: "",
    loanCommissionDetailData:null
}


const loanCommissionlice = createSlice({
    name: "loanCommissions",
    initialState,
    reducers: {
      
        setAllTransactionLoading: (state, action) => {
            state.allTransactionLoading = action.payload
        },
        setLAllTransactionError: (state, action) => {
            state.allTransactionError = action.payload
        },

        saveAllTransactionCommission: (state, action) => {

            state.allTransactionData = action?.payload
        },
        saveAllTransactionPagination: (state, action) => {
            state.allTransactionPagination= action.payload
        },

        setLoanCommissionDetailLoading: (state, action) => {
            state.loanCommissionDetailLoading = action.payload
        },
        setLoanCommissionDetailError: (state, action) => {
            state.loanCommissionDetailError = action.payload
        },
        saveLoanCommissionDetail: (state, action) => {
            state.loanCommissionDetailData = action.payload
        },
        resetAllTransactions: (state) => {
            state.allTransactionData = []
            state.allTransactionError=""
            state.allTransactionLoading=false
            state.allTransactionPagination=InitPagination
        },
        resetLoanCommissionDetails: (state) => {
            state.loanCommissionDetailLoading = false
            state.loanCommissionDetailError=""
            state.loanCommissionDetailData=null
        }
       ,
    }
})


export const {
  
    setAllTransactionLoading,
    setLAllTransactionError,
    saveAllTransactionCommission,
    saveAllTransactionPagination,
    setLoanCommissionDetailLoading,
    setLoanCommissionDetailError,
    saveLoanCommissionDetail,
    resetAllTransactions,
    resetLoanCommissionDetails
} = loanCommissionlice.actions


const loanComissionsReducer = loanCommissionlice.reducer

export default loanComissionsReducer 