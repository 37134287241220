import { parseError, parseJwt, saveSession } from '../../../utils/server';
import { AppThunk } from '../../../store';
import { api_login } from '../authAPI';
import { IUserLogin } from '../authAPI/authAPI.types';
import { clearUser, saveUser, setLoginLoading, setLoginError, resetLoginError } from '.';
import { resetApprovalRecords } from '../../Dashboard/LoanRequestApproval/LoanRequestApprovalSlice';


export const login = (params: IUserLogin): AppThunk => async (dispatch, getState) => {
    try {
        const {
            auth: { loginError }
        } = getState()

        if (loginError) dispatch(setLoginError(""));
        dispatch(setLoginLoading(true))
        

        const response = await api_login(params);
        const { accessToken, expiresIn } = response.responseBody;
        const user = parseJwt(accessToken);

        saveSession({ accessToken, expiresIn, });

        dispatch(saveUser(user))
    } catch (error: any) {
        dispatch(setLoginError(parseError(error)))
    } finally {
        dispatch(setLoginLoading(false))
    }
};

export const logout = (): AppThunk => async (dispatch) => {
    try {
        localStorage.clear();
        dispatch(resetApprovalRecords())
        dispatch(clearUser())
    } catch (error) {
    }
};

export const getAuthFromLocalStorage = () => {
    let auth = {
        user: null,
        isAuthenticated: false,
    };

    try {
        const accessToken = localStorage.getItem("accessToken")

        if (accessToken) {
            const user = parseJwt(accessToken);
            auth = {
                ...auth,
                user,
                isAuthenticated: true
            }
        }
    } catch (error) {

    } finally {
        return auth
    }
}

export const resetErrorMessage = (): AppThunk => async (dispatch) => {

    dispatch(resetLoginError())
}