import { createSlice } from '@reduxjs/toolkit';
import { ILOANREQUESTDETAILS  } from './loanRequestDetailsSlice.types';



export const initialState: ILOANREQUESTDETAILS = {
   loanDetailsData:null,
   loanRequestDetailsloading:false,
   loanRequestDetailsSuccess:false,
   loanRequestDetailsError:""
};


const loanRequestDetailsSlice = createSlice({
    name: "loanRequestDetailsId",
    initialState,
    reducers: {
        saveLoanRequestDetails: (state, action) => {
            state.loanDetailsData = action?.payload?.responseBody
           
        },
        setLoading: (state, action) => {
            state.loanRequestDetailsloading = action.payload
        },
        saveLoanRequestDetailsError: (state, action) => {
            state.loanRequestDetailsError = action.payload
        },
        resetLoanRequestDetailsState: (state) => {
            state.loanRequestDetailsError =""
            state.loanRequestDetailsloading = false 
            state.loanDetailsData =null
            state.loanRequestDetailsSuccess=false
        },
    }
})

export const {saveLoanRequestDetails,setLoading,saveLoanRequestDetailsError,resetLoanRequestDetailsState } = loanRequestDetailsSlice.actions
const loanRequestDetailsReducer = loanRequestDetailsSlice.reducer


export default loanRequestDetailsReducer 

