import { Config } from "./type";

const CONFIG_PRODUCTION: Config = {
  VERSION: "0.0.1",
  BASE_URL: "https://wcl-user-management.moniepoint.com/api/v1",
  WCL_PORTFOLIO_FRONTOFFICE_URL: "https://wcl-portfolio-service.moniepoint.com/api/v1",
  LOCATION_BASE_URL: "https://location-v2.api.kuwego.mp.monnify.com/api/v1",
  MAPS_API_KEY: "AIzaSyBRdXreiqf0s-ak95kUG_Ws_3ms7CfG5QE",
  excluded_features: []
};


export default CONFIG_PRODUCTION;
