import { createSlice } from "@reduxjs/toolkit";
import { IApprovalRecord, ILoanApprovalState } from "./types";

const initialState: ILoanApprovalState = {
    approvalRecords: {},
    approvalError: "",
    approvalLoading: false,
    approvalResponse: null,
    isApprove:false,
}


const approvalSlice = createSlice({
    name: "loanApproval",
    initialState,
    reducers: {
        setApprovalError: (state, action) => {
            state.approvalError = action.payload
        },
        setApprovalLoading: (state, action) => {
            state.approvalLoading = action.payload
        },
        saveApprovalRecord: (state, action: { type: string, payload: { data: IApprovalRecord, key: string }}) => {
            state.approvalRecords[action.payload.key] = action.payload.data
        },
        saveApprovalResponse: (state, action) => {
            state.approvalResponse = action.payload
        },
        saveIsApprove:(state,action)=>{
            state.isApprove=action.payload
        },
        resetApprovalRecords: (state) => {
            state.approvalRecords = {}
        }
    }
})


export const {
    saveApprovalRecord,
    setApprovalError,
    setApprovalLoading,
    saveApprovalResponse,
    saveIsApprove,
    resetApprovalRecords,
} = approvalSlice.actions


const approvalReducer = approvalSlice.reducer
export default approvalReducer