import { createSlice } from '@reduxjs/toolkit';
import { IAuthState } from './authSlice.types';



export const initialState: IAuthState = {
    user: null,
    isAuthenticated: false,
    loginError: "",
    loginLoading: false,
    activationError: "",
    resetPasswordLoading: false,
    resetPasswordError: "",
    forgetPasswordError:"",
};


const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        saveUser: (state, action) => {
            state.user = action.payload
            state.isAuthenticated = true
        },
        setLoginLoading: (state, action) => {
            state.loginLoading = action.payload
        },
        setLoginError: (state, action) => {
            state.loginError = action.payload
        },
        resetLoginError: (state) => {
            state.loginError = ""
        },
        clearUser: (state) => {
            state.user = null
            state.isAuthenticated = false
        },
        setActivationError: (state, action) => {
            state.activationError = action.payload
        },
        resetActivationError: (state) => {
            state.activationError = ""
        },
        setResetPasswordLoading: (state, action) => {
            state.resetPasswordLoading = action.payload
        },
        setResetPasswordError: (state, action) => {
            state.resetPasswordError = action.payload
        },
        setForgetPasswordError: (state, action) => {
            state.forgetPasswordError = action.payload
        }

    }
})

export const {
    saveUser,
    setLoginLoading,
    resetLoginError,
    setLoginError,
    clearUser,
    setActivationError,
    resetActivationError,
    setResetPasswordError,
    setResetPasswordLoading,
    setForgetPasswordError,
} = authSlice.actions

const authReducer = authSlice.reducer

export default authReducer

