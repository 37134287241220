import { createSlice } from "@reduxjs/toolkit";
import { ILoanRequestBusinessDetailsState } from "./types";

const initialState: ILoanRequestBusinessDetailsState = {
    loanRequestBusinessDetails: null,
    loanRequestBusinessDetailsError: "",
    loanRequestBusinessDetailsLoading: false
}


const loanRequestBusinessDetailsSlice = createSlice({
    name: "loanRequestBusinessDetails",
    initialState,
    reducers: {
        setLoanRequestBusinessDetailsLoading: (state, action) => {
            state.loanRequestBusinessDetailsLoading = action.payload
        },
        setLoanRequestBusinessDetailsError: (state, action) => {
            state.loanRequestBusinessDetailsError = action.payload
        },
        saveLoanRequestBusinessDetails: (state, action) => {
            state.loanRequestBusinessDetails = action.payload
        },

        resetLoanRequestBusinessDetailsState: (state) => {
            state.loanRequestBusinessDetails = null
            state.loanRequestBusinessDetailsError = ""
            state.loanRequestBusinessDetailsLoading = false
        }
    }
})


export const {
    setLoanRequestBusinessDetailsLoading,
    setLoanRequestBusinessDetailsError,
    saveLoanRequestBusinessDetails,
    resetLoanRequestBusinessDetailsState,
} = loanRequestBusinessDetailsSlice.actions

const loanRequestBusinessDetailsReducer = loanRequestBusinessDetailsSlice.reducer

export default loanRequestBusinessDetailsReducer
