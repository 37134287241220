import server from "../../../server";
import { IResponse } from "../../../server/server.types";
import { ILoginResponse, IUserLogin, IUserActivate, IActivateResponse, IResetPassword, IForgotPassword } from "./authAPI.types";


export const api_login = async (params: IUserLogin) => {
    const response = await server.post(
        "auth/login",
        params
    );
    return response.data as IResponse<ILoginResponse>;
};

export const api_activate = async (params: IUserActivate) => {
    const response = await server.post("/users/activate", params);
    return response.data as IResponse<IActivateResponse>;
};

export const reset_password_service = async (params: IResetPassword) => {
    const response = await server.post(
        "/users/reset-password",
        params,
    )

    return response.data as IResponse<any>
}

export const forgot_password_service = async (params:IForgotPassword) => {
    const response = await server.post(
        "/users/forgot-password",
        params,
    )

    return response.data as IResponse<any>
}
